.App {
  font-family: sans-serif;
  background-color: #181717;
  color: white;
}
.center {
  text-align: center;
}

.navbar a {
  color: white;
  text-decoration: none;
}

.navbar a:hover {
  color: #c9c9c9;
}

h1 {
  padding-top: 3vh;
  padding-bottom: 3vh;
  color: #0d6efd !important;
}

/* Meldingen */
ul.timeline {
  list-style: none;
  padding-left: 5vh;
  padding-right: 5vh;
}

li {
  position: relative;
}

.item {
  margin-left: 3vh;
  padding: 10px;
}

.item:before {
  content: "";
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #0d6efd;
  position: absolute;
  left: -15px;
  top: 50%;
  transform: translateY(-40%);
}

li:not(:last-child):after {
  content: "";
  width: 2px;
  height: calc(100% + 50px);
  background: #0d6efd;
  position: absolute;
  left: -8px;
  top: 0;
}

.item-head {
  font-weight: bold;
}

.green {
  color: green;
}

.red {
  color: red;
}

.blue {
  color: blue;
}

.item-body {
  margin-left: 3vh;
}

.item-foot {
  margin-left: 3vh;
}

.bold {
  font-weight: 900;
}

.inline {
  display: inline;
}

.melding {
  color: white;
  font-size: 15px;
}

.time-ago {
  color: #818181;
  font-size: 16px;
}

.hulpverlener {
  font-size: 16px;
}

.darkmode {
  color: white;
}

.powered-by a {
  position: absolute;
  bottom: 0;
  width: 100%;
  font-size: small;
  padding-bottom: 10px;
  color: #818181;
  text-decoration: none;
}

.refresh-button {
  margin-left: auto;
  padding: 0.375rem 0.75rem;
}

.refresh-button {
  margin-left: 0.5rem;
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between; 
}


/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 992px) {
  .melding {
    color: white;
    font-size: 15px;
  }

  .time-ago {
    color: #818181;
    font-size: 16px;
  }

  .hulpverlener {
    font-size: 16px;
  }
}

/* Small devices (phones, 450px and up) */
@media (min-width: 100px) and (max-width: 450px) {
  .melding {
    color: white;
    font-size: 12px;
  }

  .time-ago {
    color: #818181;
    font-size: 13px;
  }

  .hulpverlener {
    font-size: 13px;
  }

  .refresh-button {
    margin-left: 0.5rem;
  }

  .header-container {
    display: block !important;
    align-items: center;
    justify-content: space-between; 
  }

  .header-container h1 {
    padding-bottom: 0;
  }

  .subheader-container {
    padding-bottom: 1.5rem;
  }

  .header-container h1, .subheader-container{
    text-align: center;
  }
}